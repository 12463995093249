import {Component, Input} from '@angular/core';
import {TaskMessageStatus} from "@portbase/bezoekschip-service-typescriptmodels";

@Component({
  selector: 'app-task-message-status',
  templateUrl: './task-message-status.component.html',
  styleUrls: ['./task-message-status.component.scss']
})
export class TaskMessageStatusComponent {
  @Input() messageStatus: TaskMessageStatus;
  @Input() label: string;
  @Input() reason: string;
  @Input() customLabel: string;
  @Input() header: string;
  @Input() showTooltip: boolean = true;

  get messageStatusLabel(): string {
    if (this.customLabel) {
      return this.customLabel;
    }
    switch (this.messageStatus) {
      case TaskMessageStatus.UNKNOWN: return "Nothing filled in";
      case TaskMessageStatus.SAVED: return "(Partly) filled but not sent";
      case TaskMessageStatus.DEADLINE_CLOSE: return "Deadline close, X left";
      case TaskMessageStatus.DEADLINE_MISSED: return "Deadline missed";
      case TaskMessageStatus.PENDING: return "Pending";
      case TaskMessageStatus.DELIVERED: return "Received";
      case TaskMessageStatus.ACCEPTED: return "Approved";
      case TaskMessageStatus.ACCEPTED_WITH_CONDITIONS: return "Accepted with conditions";
      case TaskMessageStatus.WARNING: return this.reason || "Data mismatch";  // TODO: New date/time proposed in case if Termianl Planning Enabled
      case TaskMessageStatus.ERROR: return this.reason || "Error";
      case TaskMessageStatus.REJECTED: return "Rejected";
    }
  }
}
