<div class="fieldset" *ngIf="areNauticalServicesApplicable(context.visit.portOfCall.port.locationUnCode)"
     [ngClass]="{'disabled' : disabled}">
  <!-- NUATICAL SERVICES (not last berth) -->
  <ng-container *ngIf="!isLastBerthVisitDeparture">
    <!-- OUTBOUND NAUTICAL SERVICES AT CURRENT BERTH-->
    <div class="row">
      <!--Pilot (for entire movement)-->
      <div class="col-md-4">
        <app-edit-nautical-service [(ngModel)]="berthVisit.nextMovement.pilotService" type="Pilot"
                                   [serviceProviders]="pilotProvider" [berthVisit]="berthVisit"
                                   [required]="berthVisit.nextMovement.order && !berthVisit.atd && !context.hasDeparted()"
                                   [isLastMovement]="lastBerth">
        </app-edit-nautical-service>
      </div>
      <!--Tugboat at departure-->
      <div class="col-md-4" *ngIf="!isLastBerthVisitDeparture">
        <div class="fieldset" [ngClass]="{'disabled' :berthVisit.atd}"
             *ngIf="context.visit.portOfCall.paDeclarationRequired">
          <app-edit-nautical-service [(ngModel)]="berthVisit.tugboatAtDeparture" type="Tugboat"
                                     (ngModelChange)="changeTugsAtArrival($event)"
                                     [serviceProviders]="tugboatProvider" [berthVisit]="berthVisit"
                                     [required]="berthVisit.nextMovement.order && !berthVisit.atd && !context.hasDeparted()">
          </app-edit-nautical-service>
        </div>
      </div>
      <!--Boatmen at departure-->
      <div class="col-md-4" *ngIf="!isLastBerthVisitDeparture">
        <div class="fieldset" [ngClass]="{'disabled' :berthVisit.atd}"
             *ngIf="context.visit.portOfCall.paDeclarationRequired">
          <app-edit-nautical-service [(ngModel)]="berthVisit.boatmenAtDeparture" type="Boatmen"
                                     (ngModelChange)="changeBoatmenAtArrival($event)"
                                     [serviceProviders]="boatmenProvider" [berthVisit]="berthVisit"
                                     [required]="berthVisit.nextMovement.order && !berthVisit.atd && !context.hasDeparted()">
          </app-edit-nautical-service>
        </div>
      </div>
    </div>
    <!-- INBOUND NAUTICAL SERVICES AT NEXT BERTH -->
    <ng-container *ngIf="nextBerthVisit && (differentNauticalServices || showNauticalServicesAtArrival)">
      <div class="row mt-4">
        <div class="col-md-4">
          <!-- Pilot always the same for entire movement -->
        </div>
        <!--Tugboat at arrival-->
        <div class="col-md-4">
          <app-edit-nautical-service [(ngModel)]="nextBerthVisit.tugboatAtArrival" type="Tugboat"
                                     [berthVisit]="nextBerthVisit" [serviceProviders]="tugboatProvider"
                                     [required]="berthVisit.nextMovement.order"
                                     [id]="nextBerthVisit.id + '-tugboatToBerth'" [tugBoatsOnEntry]="firstBerth">
          </app-edit-nautical-service>
        </div>
        <!--Boatmen at arrival-->
        <div class="col-md-4">
          <app-edit-nautical-service [(ngModel)]="nextBerthVisit.boatmenAtArrival" type="Boatmen"
                                     [berthVisit]="nextBerthVisit" [serviceProviders]="boatmenProvider"
                                     [required]="berthVisit.nextMovement.order"
                                     [id]="nextBerthVisit.id + '-boatmenToBerth'">
          </app-edit-nautical-service>
        </div>
      </div>
    </ng-container>

    <!-- OPTION TO SPECIFY INBOUND NAUTICAL SERVICES AT NEXT BERTH -->
    <div class="row mt-2">
      <div class="col">
        <div class="fieldset" [ngClass]="{'disabled' :berthVisit.atd || (nextBerthVisit && nextBerthVisit.ata)}">
          <!--Order nautical services-->
          <div class="d-flex justify-content-end align-items-end" *ngIf="!(lastBerth && this.context.visit.nextDeclarant)">
            <div>
              <a *ngIf="nextBerthVisit && !(differentNauticalServices || showNauticalServicesAtArrival)" class="me-2 fst-italic" (click)="toggleNauticalServicesAtArrival()">Different nautical services at arrival</a>
              <a *ngIf="nextBerthVisit && (differentNauticalServices || showNauticalServicesAtArrival)" class="me-2 fst-italic" (click)="toggleNauticalServicesAtArrival()">Same nautical services at arrival</a>
              <button *ngIf="!berthVisit.atd && !lastBerth" class="btn btn-info me-1" (click)="copyMovement()">Copy movement</button>
              <button *ngIf="!berthVisit.atd && !lastBerth" class="btn btn-info me-1" (click)="copyNauticalServices()">Copy nautical services</button>
              <ng-container *ngIf="context.visit.orderNauticalServices">
                <button *ngIf="berthVisit.nextMovement.order" class="btn btn-danger" (click)="cancelMovement()">Cancel
                  Order
                </button>
                <button *ngIf="!berthVisit.nextMovement.order && !!berthVisit.berth" class="btn btn-info"
                        (click)="orderMovement()">Order Movement</button>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- NUATICAL SERVICES (last berth) -->
  <ng-container *ngIf="isLastBerthVisitDeparture">
    <div class="row always-enabled validate-for-next-declarant">
      <ng-container *ngIf="context.visit.nextVisitDeclaration?.lastBerthVisitDeparture as lastBerthVisitDeparture">
        <!--Pilot at departure-->
        <div class="col-md-4">
          <app-edit-nautical-service [(ngModel)]="lastBerthVisitDeparture.nextMovement.pilotService" type="Pilot"
                                     [serviceProviders]="pilotProvider" [berthVisit]="berthVisit"
                                     [isLastMovement]="lastBerth">
          </app-edit-nautical-service>
        </div>
        <!--Tugboat at departure-->
        <div class="col-md-4 border-start border-end">
          <div class="fieldset" [ngClass]="{'disabled' :berthVisit.atd}"
               *ngIf="context.visit.portOfCall.paDeclarationRequired">
            <app-edit-nautical-service [(ngModel)]="lastBerthVisitDeparture.tugboatAtDeparture" type="Tugboat"
                                       [serviceProviders]="tugboatProvider"
                                       [berthVisit]="berthVisit"></app-edit-nautical-service>
          </div>
        </div>
        <!--Boatmen at departure-->
        <div class="col-md-4">
          <app-edit-nautical-service [(ngModel)]="lastBerthVisitDeparture.boatmenAtDeparture" type="Boatmen"
                                     [serviceProviders]="boatmenProvider"
                                     [berthVisit]="berthVisit"></app-edit-nautical-service>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="nextBerthVisit">
      <div class="row always-enabled">
        <div class="col-md-4">
          <span class="text-secondary">Arrival at {{nextBerthVisit.berth?.name}}</span>
        </div>
        <!--Tugboat at arrival-->
        <div class="col-md-4 border-end">
          <app-edit-nautical-service [(ngModel)]="nextBerthVisit.tugboatAtArrival" type="Tugboat"
                                     [berthVisit]="nextBerthVisit" [serviceProviders]="tugboatProvider"
                                     [id]="nextBerthVisit.id + '-tugboatToBerth'" [tugBoatsOnEntry]="firstBerth">
          </app-edit-nautical-service>
        </div>
        <!--Boatmen at arrival-->
        <div class="col-md-4" *ngIf="nextBerthVisit">
          <app-edit-nautical-service [(ngModel)]="nextBerthVisit.boatmenAtArrival" type="Boatmen"
                                     [berthVisit]="nextBerthVisit" [serviceProviders]="boatmenProvider"
                                     [id]="nextBerthVisit.id + '-boatmenToBerth'">
          </app-edit-nautical-service>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
