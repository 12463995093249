<ng-container *ngIf="berthVisit">
  <ng-container *ngIf="context.berthVisitInTerminalPlanningMode(berthVisit) else berthVisitDetails">
    <div class="row fieldset" [ngClass]="{'disabled': !terminalPlanningActive}">
      <div class="col-md">
        <app-form-group label="Stevedore">
          <app-select [ngModel]="codeToStevedore(berthVisit.stevedore?.shortName)"
                      [options]="stevedores"
                      (ngModelChange)="setStevedore($event)"
                      [formatter]="utils.stevedoreFormatter"
                      [disabled]="!!terminalVisit?.status"
                      required
                      placeholder="Select stevedore">
          </app-select>
        </app-form-group>
        <app-form-group class="fieldset disabled" *ngIf="callId" label="Call id">
          <input [ngModel]="callId" disabled id="{{berthVisit.id}}-callId" appDecimalNumber [maxDecimals]="0" class="form-control">
        </app-form-group>
        <app-form-group *ngIf="berthVisit.terminal" label="Terminal">
          <app-select [ngModel]="codeToTerminal(berthVisit.terminal.terminalCode)"
                      [options]="terminals"
                      (ngModelChange)="setTerminal($event)"
                      [formatter]="terminalNameFormatter"
                      disabled="true" dataKey="terminalCode">
          </app-select>
        </app-form-group>
        <app-form-group *ngIf="showUseTerminalBollards()"
                        [disableInvalidFeedback]="true" class="was-validated">
          <!-- force  display of validation errors -->
          <label for="{{berthVisit.id}}-bollardFrom">Bollards</label>
          <div class="d-flex">
            <div class="input-group me-3">
              <span class="input-group-text" id="{{berthVisit.id}}-bollardFrom">from</span>
              <input [ngModel]="getTerminalVisitBollardFrom" disabled
                     [id]="berthVisit.id + '-bollardFrom'" appDecimalNumber class="form-control">
            </div>
            <div class="input-group">
              <span class="input-group-text" id="{{berthVisit.id}}-bollardTo">to</span>
              <input [ngModel]="getTerminalVisitBollardTo" disabled
                     [id]="berthVisit.id + '-bollardTo'" appDecimalNumber class="form-control">
            </div>
          </div>
        </app-form-group>
        <app-form-group *ngIf="terminalVisit?.info?.mooringOrientation" label="Mooring">
          <app-select [(ngModel)]="terminalVisit.info.mooringOrientation" [options]="terminalMoorings" disabled
                      [formatter]="terminalMooringFormatter" id="{{berthVisit.id}}-terminalMooring"></app-select>
        </app-form-group>
        <app-form-group *ngIf="vesselServices.length > 0"
                        label="Vessel service">
          <app-select [ngModel]="codeToVesselService(berthVisit.vesselServiceCode)"
                      [options]="vesselServices" required
                      [formatter]="vesselServiceNameFormatter"
                      (ngModelChange)="setVesselService($event)"
                      placeholder="Select a vessel service" dataKey="code">
          </app-select>
        </app-form-group>
        <app-form-group label="ETA Berth">
          <app-date-field [(ngModel)]="berthVisit.eta" required
                          [appCompare]="quarterHourEta"
                          [compareInline]="true"
                          [compareFormatter]="formatDate"
                          comparePreamble="Terminal reported: "></app-date-field>
          <span *ngIf="previousBerthVisitNotChronological()" class="ng-invalid invalid-feedback mb-2">
            ETA is before the departure of the previous berth</span>
        </app-form-group>
        <app-form-group label="ETD Berth">
          <app-date-field [(ngModel)]="berthVisit.etd" required
                          [appCompare]="quarterHourEtd"
                          [compareInline]="true"
                          [compareFormatter]="formatDate"
                          comparePreamble="Terminal reported: "></app-date-field>
          <span *ngIf="berthVisitNotChronological()" class="ng-invalid invalid-feedback mb-2">
              Departure should be after arrival
          </span>
        </app-form-group>
      </div>
      <ng-container *ngIf="context.isMultiBerthTerminal(berthVisit) else singleBerth">
        <div class="col-md">
          <app-multi-berth [stevedore]="berthVisit.stevedore" [berthVisit]="berthVisit"></app-multi-berth>
        </div>
      </ng-container>
      <ng-template #singleBerth>
        <div class="col-md">
          <app-terminal-planning-number-info [terminalVisit]="terminalVisit" [berthVisit]="berthVisit"></app-terminal-planning-number-info>
          <app-form-group>
            <app-info class="formGroupLabel">
              <label>Operator remarks</label>
              <div class="tooltipContent">These remarks are shared between operator and terminal</div>
            </app-info>
            <textarea class="form-control" [(ngModel)]="berthVisit.operatorRemarks"></textarea>
          </app-form-group>
        </div>
      </ng-template>
    </div>
    <div class="d-flex flex-row-reverse" *ngIf="terminalPlanningActive">
      <div class="d-lg-block ms-2">
        <app-terminal-planning-admin [berthVisit]="berthVisit" [terminalVisit]="terminalVisit"></app-terminal-planning-admin>
      </div>
      <div *ngIf="canApprovePlanning" class="d-lg-block ms-2">
        <button class="btn btn-secondary" type="button" (click)="approvePlanning(); $event.preventDefault();">Approve planning</button>
      </div>
    </div>
  </ng-container>
  <ng-template #berthVisitDetails>
    <div class="row fieldset" [ngClass]="{'validate-for-next-declarant': isNextBerthVisit && context.isOrganisationNextDeclarant(), 'disabled': berthVisit.terminalPlanningEnabled && !terminalPlanningActive}">
      <div class="col-md">
        <!-- Select berth -->
        <app-select-berth [berthVisit]="berthVisit" [isBerthOfTransfer]="isBerthOfTransfer" [firstBerth]="firstBerth" (change)="berthChanged()">
        </app-select-berth>
        <div *ngIf="suggestedBerth">
          <app-status-alert type="info">Berth {{suggestedBerth}} has been selected based on the chosen bollards.</app-status-alert>
        </div>
        <div *ngIf="outsideRange">
          <app-status-alert type="info">The center of the chosen bollards does not match with the chosen berth. Please select another berth.</app-status-alert>
        </div>
        <div *ngIf="planningBasedForcedWarning">
          <app-status-alert type="info" (close)="planningBasedForcedWarningOff()">'Planning based on' set to '1st Berth'</app-status-alert>
        </div>
        <app-form-group class="fieldset disabled" *ngIf="berthVisit.terminalPlanningEnabled" label="Call id">
          <input [ngModel]="callId" disabled id="{{berthVisit.id}}-callId" appDecimalNumber [maxDecimals]="0" class="form-control">
        </app-form-group>
        <!--Bollard from and to-->
        <div class="fieldset"
             [ngClass]="{'disabled' :berthVisit.ata || berthVisit.atd || berthVisit.terminalPlanningEnabled}"
             *ngIf="context.visit.portOfCall.paDeclarationRequired">
          <app-form-group [disableInvalidFeedback]="true" class="was-validated"> <!-- force  display of validation errors -->
            <label for="{{berthVisit.id}}-bollardFrom">Bollards</label>
            <div class="d-flex">
              <div class="input-group me-3">
                <span class="input-group-text" id="bollard-from">from</span>
                <input [(ngModel)]="berthVisit.bollardFrom" (blur)="updateBollardsFrom($event)"
                       [required]="!!berthVisit.bollardTo && areBollardsUpdatable()"
                       [id]="berthVisit.id + '-bollardFrom'" appDecimalNumber class="form-control {{bollardFromUnequalToTerminal ? 'has-diff' : ''}}">
              </div>
              <div class="input-group">
                <span class="input-group-text" id="bollard-to">to</span>
                <input [(ngModel)]="berthVisit.bollardTo" (blur)="updateBollardsTo($event)"
                       [required]="!!berthVisit.bollardFrom && areBollardsUpdatable()"
                       [id]="berthVisit.id + '-bollardTo'" appDecimalNumber class="form-control  {{bollardToUnequalToTerminal ? 'has-diff' : ''}}">
              </div>
            </div>
            <div *ngIf="showUseTerminalBollards()" style="text-align: end;">
              <input type="checkbox" id="{{berthVisit.id}}-useTerminalBollards" [(ngModel)]="useTerminalBollardsChecked"/>
              &nbsp;Use terminal request ({{getTerminalVisitBollardFrom}} - {{getTerminalVisitBollardTo}})
              <app-info>
                <span class="tooltipContent">Use requested Terminal Bollards</span>
              </app-info>
            </div>
          </app-form-group>
        </div>

        <div>
          <app-berth-visit-times [firstBerth]="firstBerth" [berthVisit]="berthVisit" [terminalVisit]="terminalVisit" [isBerthOfTransfer]="isBerthOfTransfer"></app-berth-visit-times>
        </div>

        <!--Dependency on vessel-->
        <ng-container *ngIf="context.visit.orderIncomingMovement && berthVisit.berth && firstBerth">
          <app-exchange-at-berth [firstBerthVisit]="berthVisit"></app-exchange-at-berth>
        </ng-container>
        <ng-container *ngIf="berthVisit.terminalPlanningEnabled">
          <app-terminal-planning-number-info [terminalVisit]="terminalVisit" [berthVisit]="berthVisit">
          </app-terminal-planning-number-info>
        </ng-container>
      </div>
      <div class="col-md">
        <div class="fieldset" [ngClass]="{'disabled' :berthVisit.ata || berthVisit.atd}">
          <!--Mooring-->
          <app-form-group *ngIf="context.visit.portOfCall.paDeclarationRequired">
            <label for="{{berthVisit.id}}-mooring" [ngClass]="{'required': requiredForOrder}">Mooring</label>
            <app-select [(ngModel)]="berthVisit.mooring" [required]="requiredForOrder" [options]="moorings"
                        [formatter]="mooringFormatter" id="{{berthVisit.id}}-mooring"
                        (ngModelChange)="firstBerth && context.visit.orderIncomingMovement && context.updateCalculatedEtas()"
                        class="{{mooringUnequalToTerminal ? 'has-diff' : ''}}">
            </app-select>
            <div *ngIf="showUseTerminalMooringCheckbox()" style="text-align: end;">
              <input type="checkbox" id="{{berthVisit.id}}-useTerminalMooring" [(ngModel)]="useTerminalMooringCheckbox"/>
              &nbsp;Use terminal request
              <app-info>
                <span class="tooltipContent">Use requested Terminal Mooring</span>
              </app-info>
            </div>
          </app-form-group>
          <!--Purpose of visit-->
          <app-form-group>
            <label for="{{berthVisit.id}}-visitPurposes" [ngClass]="{'required': requiredForOrder}">Visit purpose</label>
            <app-multiselect [(ngModel)]="berthVisit.visitPurposes" [options]="visitPurposes"
                             [formatter]="visitPurposeFormatter" id="{{berthVisit.id}}-visitPurposes" [required]="requiredForOrder"
                             placement="top-start" title="Select any number of visit purposes" [horizontal]="true"></app-multiselect>
          </app-form-group>
          <!-- Agent transfer -->
          <app-agent-transfer *ngIf="showAgentTransfer()"></app-agent-transfer>
          <!--Remarks-->
          <ng-container *ngIf="context.visit.portOfCall.paDeclarationRequired">
            <app-form-group>
              <label for="{{berthVisit.id}}-remarks">Remarks</label>
              <textarea [(ngModel)]="berthVisit.remarks" rows="3" id="{{berthVisit.id}}-remarks" maxlength="152"
                        class="form-control"></textarea>
            </app-form-group>
          </ng-container>
          <!-- Operator remarks (shared with the terminal for Terminal planning) -->
          <app-form-group *ngIf="terminalVisit">
            <app-info class="formGroupLabel">
              <label>Operator remarks</label>
              <div class="tooltipContent">These remarks are shared between operator and terminal</div>
            </app-info>
            <textarea class="form-control" [(ngModel)]="berthVisit.operatorRemarks"></textarea>
          </app-form-group>
        </div>
      </div>
      <div class="d-lg-block ms-2">
        <app-terminal-status-admin [berthVisit]="berthVisit" [terminalVisit]="terminalVisit"></app-terminal-status-admin>
      </div>
    </div>
  </ng-template>
</ng-container>
