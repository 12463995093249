<div class="row g-0 bg-secondary text-light py-2">
  <div class="col-3" style="min-width: 7rem; max-width: 16.75rem"><span class="ps-2">Handling</span></div>
  <div class="col d-none d-xl-block ps-2">
    <div class="row g-0">
      <div class="col">Stowage number</div>
      <div class="col ps-2" style="max-width: 24rem">Details</div>
    </div>
  </div>
  <div class="col-auto" style="width: 7rem"></div>
</div>

<div class="accordion" id="handlingsAccordion">
  <div *ngFor="let berthVisit of context.dangerousGoodsDeclaration.dangerousGoods.berthVisits; trackBy: berthVisitById">
    <div class="row g-0 py-3 bg-location-light">
      <div class="col">
        <div class="fa fa-lg fa-map-marker-alt text-portbase-blue mx-2"></div>
        <span>{{berthVisit.berth?.name + " - " + berthVisit.berth?.code | titlecase}}</span>
      </div>
      <div class="col-auto always-enabled" style="width: 1.5rem">
        <button class="btn btn-link collapsed p-0" type="button" data-bs-toggle="collapse"
                [attr.data-bs-target]="'#stowages-collapse-' + berthVisit.id">
          <span class="fa fa-chevron-right"></span>
        </button>
      </div>
      <div class="col-12 collapse" [id]="'stowages-collapse-' + berthVisit.id">
        <div class="fieldset card-body bg-transparent border-0 py-0 my-0" [ngClass]="{'disabled' :true}">
          <app-stowages [stowageArray]="berthVisit.stowageAtArrival" readonly></app-stowages>

          <div class="text-end mt-3 always-enabled">
            <a (click)="downloadExcel(berthVisit.stowageAtArrival)" class="align-middle text-portbase-blue"
               style="cursor: pointer" tabindex="-1">
              Download excel <span class="fa fa-file-download"></span></a>
          </div>
        </div>
      </div>
    </div>

    <div id="{{berthVisit.id}}" dragula="handlings" [(dragulaModel)]="berthVisit.handlings">
      <span class="fa fa-1x" *ngIf="berthVisit.handlings.length===0"></span>
      <div *ngFor="let handling of berthVisit.handlings; trackBy: handlingById">
        <div class="row g-0 py-2">
          <div class="col-3" style="min-width: 7rem; max-width: 16.75rem">
            <span class="input-group">
              <span style="margin-left: 2rem;" [ngSwitch]="handling.type">
                <span class="drag-handle grabbable">
                  <span style="width: 5rem" *ngSwitchCase="'discharge'" class="badge text-bg-info drag-handle">Discharge</span>
                  <span style="width: 5rem" *ngSwitchCase="'washing'" class="badge text-bg-warning drag-handle">Washing</span>
                  <span style="width: 5rem" *ngSwitchCase="'ventilation'" class="badge text-bg-danger drag-handle">Ventilation</span>
                  <span style="width: 5rem" *ngSwitchCase="'inerting'" class="badge text-bg-secondary drag-handle">Inerting</span>
                  <span style="width: 5rem" *ngSwitchCase="'loading'" class="badge text-bg-success drag-handle">Loading</span>
                  <span style="width: 5rem" *ngSwitchCase="'blending'" class="badge text-bg-primary drag-handle">Blending</span>
                  <span style="width: 5rem" *ngSwitchCase="'restow'" class="badge text-bg-dark drag-handle">Restow</span>
                </span>
              </span>
            </span>
          </div>

          <div class="col d-none d-xl-block ps-2">
            <div class="row g-0" *ngFor="let group of handling.details">
              <div class="col">
              <span *ngFor="let position of group.stowageNumbers" class="badge text-bg-secondary"
                    style="margin: 0.125rem">{{position}}</span>
              </div>
              <div class="col ps-2 d-none d-xl-block ps-2 position-relative fst-italic" style="max-width: 24rem">
                <div class="d-inline-block text-truncate" style="max-width: 100%">{{group.details}}</div>
              </div>
            </div>
          </div>

          <div class="col d-xl-none"></div>

          <div class="col-auto ps-2">
            <label style="width: 5rem" [attr.data-bs-target]="'#handling-details-collapse-' + handling.id"
                   [ngClass]="{'btn-invalid' : handling.ngInvalid, 'btn-warning' : handling['prewashingRequired']}" (click)="sortLoadingAndBlending(handling)"
                   data-bs-toggle="collapse" class="btn btn-info">Edit
            </label>
          </div>
          <div class="col-auto text-end d-none d-sm-block" style="width: 2rem">
            <button title="Delete handling" (click)="deleteHandling(handling)"
                    class="btn" type="button" tabindex="-1"><span class="fa fa-trash text-secondary"></span>
            </button>
          </div>

        </div>

        <div class="row" [appValidator]="handling">
          <div class="col-12 collapse" data-bs-parent="#handlingsAccordion"
               [id]="'handling-details-collapse-' + handling.id">
            <div class="card card-body bg-light mt-2 mb-4">
              <div class="card-title row">
                <h5 class="col">Edit {{handling.type | titlecase}}</h5>
                <button title="Delete handling" (click)="deleteHandling(handling)"
                        class="btn mt-n2 ms-auto d-sm-none" type="button" tabindex="-1"><span
                  class="fa fa-trash text-secondary"></span>
                </button>
              </div>
              <div [ngSwitch]="handling.type">
                <app-edit-discharge *ngSwitchCase="'discharge'" [handling]="handling"></app-edit-discharge>
                <app-edit-cleaning *ngSwitchCase="'washing'" [handling]="handling"></app-edit-cleaning>
                <app-edit-ventilation *ngSwitchCase="'ventilation'" [handling]="handling"></app-edit-ventilation>
                <app-edit-inerting *ngSwitchCase="'inerting'" [handling]="handling"></app-edit-inerting>
                <app-edit-loading *ngSwitchCase="'loading'" [handling]="handling"></app-edit-loading>
                <app-edit-blending *ngSwitchCase="'blending'" [handling]="handling"></app-edit-blending>
                <app-edit-restow *ngSwitchCase="'restow'" [handling]="handling"></app-edit-restow>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!--Add handling-->
    <div class="row my-3">
      <div class="col"></div>
      <div class="col my-1 text-end">
        <div class="dropdown">
          <button id="addHandling" type="button" class="btn btn-info dropdown-toggle" data-bs-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">Add Handling
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <button *ngFor="let type of berthVisit.allowedHandlings" type="button" class="dropdown-item"
                    (click)="addHandling(type, berthVisit.id)"
                    data-bs-toggle="collapse">{{type | titlecase}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row g-0 py-3 bg-location-light">
  <div class="col">
    <div class="fa fa-lg fa-map-marker-alt text-portbase-blue mx-2"></div>
    <span>{{context.dangerousGoodsDeclaration.dangerousGoods.exitPoint.name | titlecase}}</span>
  </div>
  <div class="col-auto always-enabled" style="width: 1.5rem">
    <button class="btn btn-link p-0" type="button" data-bs-toggle="collapse"
            data-bs-target="#stowages-collapse-exit-point">
      <span class="fa fa-chevron-right"></span>
    </button>
  </div>
  <div class="col-12 collapse" id="stowages-collapse-exit-point">
    <div class="fieldset card card-body bg-transparent border-0 py-0 my-0" [ngClass]="{'disabled' :true}">
      <app-stowages [stowageArray]="context.dangerousGoodsDeclaration.dangerousGoods.exitPoint.stowageAtArrival" readonly></app-stowages>

      <div class="text-end mt-3 always-enabled">
        <a (click)="downloadExcel(context.dangerousGoodsDeclaration.dangerousGoods.exitPoint.stowageAtArrival)" class="align-middle text-portbase-blue"
           style="cursor: pointer" tabindex="-1">
          Download excel <span class="fa fa-file-download"></span></a>
      </div>
    </div>
  </div>
</div>
