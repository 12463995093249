import {Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {Fumigant, Fumigator, GoodDataUnion, Hold} from '@portbase/bezoekschip-service-typescriptmodels';
import {VisitContext} from '../../../visit-context';
import {StowageModelUnion} from '../../dangerous-goods.model';
import {dispatchChangeEvent} from "../../../../common/utils";
import {PortvisitUtils} from '../../../../refdata/portvisit-utils';

type FumigationMethod = 'sleeves' | 'tablets';

@Component({
  selector: 'app-edit-stowage',
  templateUrl: './edit-stowage.component.html',
  styleUrls: ['./edit-stowage.component.css']
})
export class EditStowageComponent {
  context = VisitContext;
  refData = PortvisitUtils;

  @Input() stowage: StowageModelUnion;
  @Input() disableGoodEdit: boolean;
  @Input() toggleTankContainer;
  @Input() isContainerItem: boolean;
  @Output() goodDataChanged = new EventEmitter<GoodDataUnion>();

  constructor(private elementRef: ElementRef) {
  }

  toggleOption = (stowage) => {
    this.toggleTankContainer(stowage);
  }

  PHOSPHIDES = [Fumigant.PHOSPHINE, Fumigant.ALUMINIUM_PHOSPHIDE, Fumigant.MAGNESIUM_PHOSPHIDE];

  FUMIGANTS: Fumigant[] = [Fumigant.AMMONIA, Fumigant.CO, Fumigant.CO2, Fumigant.CHLOROPICRIN,
    Fumigant.FORMALDEHYDE, Fumigant.METHYLBROMIDE, Fumigant.PHOSPHINE, Fumigant.SULFURYL_FLUORIDE, Fumigant.N2,
    Fumigant.ALUMINIUM_PHOSPHIDE, Fumigant.MAGNESIUM_PHOSPHIDE];
  fumigantFormatter = (value: Fumigant) => {
    switch (value) {
      case 'AMMONIA':
        return 'Ammonia';
      case 'CO':
        return 'Carbon monoxide';
      case 'CO2':
        return 'Carbon dioxide';
      case 'CHLOROPICRIN':
        return 'Chloropicrin';
      case 'FORMALDEHYDE':
        return 'Formaldehyde';
      case 'METHYLBROMIDE':
        return 'Methyl bromide';
      case 'PHOSPHINE':
        return 'Phosphine';
      case 'SULFURYL_FLUORIDE':
        return 'Sulfuryl fluoride';
      case 'ALUMINIUM_PHOSPHIDE':
        return 'Aluminium phosphide';
      case 'MAGNESIUM_PHOSPHIDE':
        return 'Magnesium phosphide';
      case 'N2':
        return 'Nitrogen';
    }
  };

  FUMIGATORS: Fumigator[] = [Fumigator.AMS, Fumigator.ANTICIMEX, Fumigator.EWS, Fumigator.RUVOMA,
    Fumigator.SPIKE_INTERNATIONAL, Fumigator.OTHER];
  fumigatorFormatter = (value: Fumigator) => {
    switch (value) {
      case 'AMS':
        return 'Allround Marine Service (AMS)';
      case 'ANTICIMEX':
        return 'Anticimex';
      case 'EWS':
        return 'Eco Worldwide Solutions (EWS)';
      case 'RUVOMA':
        return 'Ruvoma';
      case 'SPIKE_INTERNATIONAL':
        return 'Spike International';
      case 'OTHER':
        return 'Other (fill in remarks)';
    }
  }
  FUMIGATION_METHODS: FumigationMethod[] = ['sleeves', 'tablets'];
  fumigationMethodFormatter = (value: FumigationMethod) => {
    switch (value) {
      case 'sleeves':
        return 'Sleeves';
      case 'tablets':
        return 'Tablets';
    }
  }

  get isPhosphide() {
    return this.PHOSPHIDES.indexOf((<Hold>this.stowage)?.fumigation?.fumigant) > -1;
  }

  get isMethodSleeves() {
    return this.isPhosphide && (<Hold>this.stowage)?.fumigation?.details?.type === 'sleeves';
  }

  ngAfterViewInit(): void {
    dispatchChangeEvent(this.elementRef.nativeElement);
  }

  toggleMethod(type?: FumigationMethod) {
    (<Hold>this.stowage).fumigation.details = !!type ? {
      'type': type
    } : null;
  }
}
